import { Organization } from "@squeeze-io/database";
import { OrganizationMemberRole } from "@/types/organizationMember";
import { User } from "next-auth";
import { StateCreator } from "zustand";
import { sliceResetFns } from "./resetStores";
import { Role } from "@squeeze-io/database";

interface State {
  user: User | {};
  currentOrg: Organization | {};
  initializedCurrentOrg: boolean;
  organizationMemberRoles: OrganizationMemberRole | null;
  initializedUser: boolean;
  loadingData: boolean;
}

export interface UserSlice extends State {
  getCurrentOrgSlug: () => string | undefined;
  isSuperAdmin: () => boolean;
  setCurrentUser: (user: User) => void;
  setCurrentOrg: (currentOrg: Organization) => void;
  setOrgMemberRoles: (orgs: OrganizationMemberRole) => void;
  setLoadingData: (loading: boolean) => void;
}

export const INITIAL_STATE: State = {
  user: {},
  currentOrg: {},
  initializedCurrentOrg: false,
  organizationMemberRoles: null,
  initializedUser: false,
  loadingData: false,
};
export const SWITCHER_RESET_STATE = {
  currentOrg: {},
  initializedCurrentOrg: false,
  loadingData: false,
};

export const createUserSlice: StateCreator<UserSlice, [], [], UserSlice> = (
  set,
  get
) => {
  sliceResetFns.add(() => set(SWITCHER_RESET_STATE));
  return {
    ...INITIAL_STATE,
    getCurrentOrgSlug: () => (get().currentOrg as Organization).slug,
    isSuperAdmin: () => {
      const roles = get().organizationMemberRoles;
      return roles?.role === Role.SUPERADMIN;
    },
    setCurrentUser: (user) => set({ user }),
    setLoadingData: (loading) => set({ loadingData: loading }),
    setCurrentOrg: (currentOrg) => set({ currentOrg }),
    setOrgMemberRoles: (orgs) =>
      set({
        organizationMemberRoles: orgs,
      }),
  };
};
